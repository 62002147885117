import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { isEmpty } from 'lodash';
import Modal from 'react-modal';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import { grey, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { ArchiveButton, IconButton, EditButton, Input, buttonTypes } from '@united-talent-agency/julius-frontend-components';
import { createProject, createProjectCasting, createRole as createProjectRole, createProjectNote, loadIndieNotes } from '@united-talent-agency/julius-frontend-store';
import { loadIntercom } from '../../support/intercom-loader';
import Tooltip from '@material-ui/core/Tooltip';
import cypressTags from '../../support/cypressTags';
import LaunchDarklyWrapper from '../LDWrapper/LaunchDarklyWrapper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Spinner } from '@united-talent-agency/components';
import { colors } from '../../styles/colors';

Modal.setAppElement(document.getElementById('delete-project-modal'));
Modal.setAppElement(document.getElementById('duplicate-project-modal'));

export class Title extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSaved: false,
      isEditing: false,
      name: this.props.project ? this.props.project.name : '',
      missingNameInput: false,
      showDeleteModal: false,
      showDuplicateModal: false,
      intercomBooted: false,
    };
  }

  render() {
    const { styles, editing, project, handleDeleteProject, user = {}, _loadIntercom = loadIntercom } = this.props;
    const { isEditing, name, missingNameInput, showDeleteModal, intercomBooted, showDuplicateModal } = this.state;
    const isStrikeout = project && !project.active ? styles.strikeout : '';
    const isBold = project && project.newInformation ? styles.bold : '';

    if (!isEmpty(user) && !intercomBooted) {
      _loadIntercom(user.first_name, user.last_name, user.email);
      this.setState({ intercomBooted: true });
    }

    if (isEditing || editing) {
      return (
        <div className={styles.container}>
          <ThemeProvider theme={theme}>
            <div className={styles.projectNameInput} data-cy={cypressTags.PROJECT.PROJECT_NAME_EDIT_INPUT}>
              <TextField
                value={name}
                error={missingNameInput}
                id="project-name"
                label="Name"
                placeholder="Name"
                margin="dense"
                variant="outlined"
                onChange={event => {
                  this.setState({ name: event.target.value.trimLeft(), missingNameInput: false });
                }}
              />
            </div>
          </ThemeProvider>
          <div>
            <EditButton type={buttonTypes.cancel} className={styles.icon} onClick={() => this.cancel()} />
          </div>
          <Tooltip
            title={
              this.isValidUTF8(this.state.name) ? (
                <h4 style={{ fontSize: '18px' }}>
                  Project name cannot contain restricted characters like: é, ä, ô, “ etc. Please remove them to
                  continue.
                </h4>
              ) : (
                ''
              )
            }
          >
            <span data-cy={cypressTags.PROJECT.PROJECT_NAME_EDIT_SAVE_BUTTON}>
              <EditButton
                type={buttonTypes.save}
                className={styles.icon}
                onClick={() => this.save()}
                disabled={this.isValidUTF8(this.state.name)}
              />
            </span>
          </Tooltip>
        </div>
      );
    }

    return (
      <LaunchDarklyWrapper
        render={flags => {
          return (
            <div className={styles.container}>
              <span data-cy={cypressTags.PROJECT.PROJECT_TITLE} className={classnames(styles.name, isStrikeout, isBold)}>
                {name}
              </span>
              <span data-cy={cypressTags.PROJECT.PROJECT_NAME_EDIT_BUTTON}>
                <EditButton
                  type={buttonTypes.edit}
                  className={styles.icon}
                  onClick={() => {
                    this.setState({ isEditing: true });
                    this.props.setEdit(true);
                  }}
                />
              </span>
              <div className={styles.toggleButtons}>
                { (flags?.duplicateButton || process.env.NODE_ENV === 'test') &&
                <div id='duplicate-btn' data-cy={cypressTags.PROJECT.PROJECT_DUPLICATE_BUTTON}>
                  <IconButton
                    onClick={() => this.setState({ showDuplicateModal: true })}
                    falseIcon="fa-clone"
                    falseText="DUPLICATE"
                  />
                </div>
                }
                <IconButton
                  onClick={() => this.setState({ showDeleteModal: true })}
                  falseIcon="fa-trash"
                  falseText="DELETE"
                />
                <ArchiveButton isArchived={project ? !project.active : false} onClick={() => this.toggleArchive()} />
                <IconButton
                  toggle={project ? project.newInfo : false}
                  onClick={() => this.toggleNewInformation()}
                  falseText="New Information"
                  falseIcon="fa-square-o"
                  trueText="New Information"
                  trueIcon="fa-check-square-o"
                />
              </div>
              <DeleteModal
                showDeleteModal={showDeleteModal}
                closeDeleteModal={() => this.setState({ showDeleteModal: false })}
                handleDelete={handleDeleteProject}
              />
              <DuplicateModal
                styles={styles}
                isOpen={showDuplicateModal}
                onRequestClose={() => this.setState({ showDuplicateModal: false })}
                handleDuplicate={(title, option) => this.duplicate(title, option)}
              />
              <div id="delete-project-modal" />
              <div id="duplicate-project-modal" />
            </div>
          );
        }}
      />
    );
  }

  cancel() {
    this.setState(resetState(this.props));
    this.props.setEdit(false);
  }

  save() {
    const { project } = this.props;

    if (!this.state.name) {
      this.setState({ missingNameInput: true });
    } else {
      if (this.state.name !== project.name) {
        const changeToSave = { name: this.state.name };
        this.saveChanges(changeToSave);
      }

      this.setState({ isEditing: false });
      this.props.setEdit(false);
    }
  }

  // eslint-disable-next-line no-undef
  isValidUTF8 = str => {
    try {
      decodeURIComponent(escape(str));
      return false;
    } catch (e) {
      return true;
    }
  };

  toggleArchive() {
    const { project } = this.props;
    const changeToSave = { active: !project.active };
    this.saveChanges(changeToSave);
  }

  toggleNewInformation() {
    const { project } = this.props;
    const changeToSave = { newInfo: !project.newInfo };
    this.saveChanges(changeToSave);
  }

  async duplicate(title, option) {
    const { project, castings, roles, literaryNotes = [], talentNotes = [] } = this.props;
    const _project = { ...project, isDuplicate: true, duplicatedFrom: project.name };
    delete _project._id;
    delete _project.created_date;
    if(_project.salesFinancing)
      _project.salesFinancing = _project?.salesFinancing?.reduce((acum, cv) => { cv?.company?._id && acum.push(cv); return acum; }, []);
    _project.name = title;
    const response = await this.props.dispatch(createProject(_project));
    const { body: newProject } = response;
    if(option === 'copyAllTabs'){
      for(const casting of castings){
        delete casting._id;
        casting.projectId = newProject._id;
        await this.props.dispatch(createProjectCasting(casting));
      }
      for(const role of roles){
        delete role._id;
        role.projectId = newProject._id;
        role.isDuplicate = true;
        await this.props.dispatch(createProjectRole(role));
      }
      let notes = [];
      try{
        const indieNotesResp = await this.props.dispatch(loadIndieNotes(project._id));
        notes = Array.isArray(indieNotesResp?.body) ? indieNotesResp.body : [];
      } catch (e) {
        console.error(e);
      }
      const notesArr = [...notes, ...literaryNotes, ...talentNotes];
      if(Array.isArray(notesArr)){
        for(const note of notesArr){
          delete note._id;
          note.isDuplicate = true;
          note.projectId = newProject._id;
          await this.props.dispatch(createProjectNote(note));
        }
      }
      // const trackingResp = await this.props.dispatch(loadTrackings(null, null, project._id));
      // const trackings = trackingResp.body;
      // for(const tracking of trackings ){
      //   await this.props.dispatch(addToTracking(tracking._id, { projects: { projects: [newProject._id], isDuplicate: true } }));
      // }
    }
    return newProject._id;
  }

  saveChanges(change) {
    const { project, saveChanges } = this.props;
    saveChanges({
      updates: {
        project: {
          [project._id]: change,
        },
      },
    });
  }
}

const DeleteModal = ({ showDeleteModal, closeDeleteModal, handleDelete }) => {
  return (
    <React.Fragment>
      <Modal
        contentLabel="Confirm Deletion"
        onRequestClose={() => closeDeleteModal()}
        isOpen={showDeleteModal}
        style={{
          content: {
            ...modalStyles.container,
          },
        }}
      >
        <div style={modalStyles.xButtonContainer}>
          <i onClick={() => closeDeleteModal()} className="fa fa-times" style={modalStyles.xButton}></i>
        </div>
        <div style={modalStyles.contentContainer}>
          <p style={modalStyles.headerText}>DELETE CONFIRMATION</p>
          <div style={modalStyles.promptText}>Are you sure you want to permanently delete this item?</div>
          <div style={modalStyles.buttonContainer}>
            <button style={modalStyles.cancelButton} onClick={() => closeDeleteModal()}>
              <i className="fa fa-times action-menu-icon" aria-hidden="true"></i>CANCEL
            </button>
            <button style={modalStyles.deleteButton} onClick={() => handleDelete()}>
              <i className="fa fa-trash action-menu-icon" aria-hidden="true"></i>DELETE
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const DuplicateModal = ({ isOpen, onRequestClose, handleDuplicate }) => {
  const [title, setTitle] = React.useState('');
  const [selectedRadio, setSelectedRadio] = React.useState('copyGeneral');
  const [loading, setLoading] = React.useState(false);
  const radioOptions = [
    { key: 'copyGeneral', label: 'Copy General Tab Only' },
    { key: 'copyAllTabs', label: 'Copy All Tabs' }
  ];

  const onDuplicateBtnClick = async () => {
    setLoading(true);
    if(title === ''){
      alert('Please verify title');
      return;
    }
    const newProjectId = await handleDuplicate(title, selectedRadio);
    setTitle('');
    window.open('/project/' + newProjectId, '_blank');
    setLoading(false);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      style={{
        content: {
          ...modalStyles.container,
          height: '300px'
        },
      }}
    >
      <div style={modalStyles.xButtonContainer}>
        <i onClick={() => onRequestClose()} className="fa fa-times" style={modalStyles.xButton}></i>
      </div>
      <div style={modalStyles.contentContainer}>
        <p style={modalStyles.headerText}>DUPLICATE</p>
        <div className='ml-5' style={{ alignSelf: 'start' }}>
          <FormControl>
            <span>Name:</span>
            <div
              data-cy={cypressTags.PROJECT.PROJECT_DUPLICATE_NAME_INPUT}
            >
              <Input
                onChange={val => setTitle(val)}
              />
            </div>
            <RadioGroup
              className='mt-2'
              aria-label="indices"
              value={selectedRadio}
              name="indices"
              onChange={(event, value) => setSelectedRadio(value)}
            >
              {radioOptions.map(option => 
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={
                  <Radio
                    color="default"
                    icon={<RadioButtonUncheckedIcon fontSize="small" />}
                    checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                    style={{
                      color: selectedRadio === 1 ? colors.primary : colors.unselected,
                      paddingLeft: 10,
                      marginTop: -6,
                      marginBottom: -6,
                      marginRight: 20,
                    }}
                  />
                }
                label={option.label}
              />
              )}
            </RadioGroup>
            <div className='d-flex justify-content-around'>
              <button
                style={duplicateStyle.button}
                onClick={() => onRequestClose()}
              >
                <i className={`fa fa-w fa-ban`} />
                Cancel
              </button>
              <button
                data-cy={cypressTags.PROJECT.PROJECT_DUPLICATE_CONFIRM_BUTTON}
                style={duplicateStyle.button}
                onClick={() => onDuplicateBtnClick()}
                disabled={title === '' || loading}
              >
                { loading ? <Spinner size={12} /> :  <i className={`fa fa-w fa-clone`} /> }
                Duplicate
              </button>
            </div>
          </FormControl>
        </div>
      </div>
    </Modal>
  );
};

export function resetState(props) {
  return {
    isEditing: false,
    name: props && props.project ? props.project.name : '',
    missingNameInput: false,
  };
}

const theme = createTheme({
  palette: {
    primary: grey,
  },
  overrides: {
    MuiFormControl: {
      root: {
        minWidth: '100%',
      },
    },
  },
});

const withStyles = styled({
  container: {
    display: 'flex',
    margin: '15px 0 0px 0',
    alignItems: 'center',
  },
  input: {
    flex: 1,
  },
  name: {
    fontSize: 24,
    fontWeight: 300,
  },
  icon: {
    marginLeft: 10,
  },
  strikeout: {
    textDecorationLine: 'line-through',
  },
  bold: {
    fontWeight: 400,
  },
  toggleButtons: {
    display: 'inherit',
    marginLeft: 'auto',
  },
  invalid: {
    border: `1px solid ${red}`,
  },
  projectNameInput: {
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 15,
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 'none',
    fontSize: 14,
    lineHeight: 1,
    backgroundColor: 'unset',
    textTransform: 'uppercase',
  },
});

const modalStyles = {
  container: {
    top: '50%',
    left: '50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    fontFamily: 'aktiv-grotesk',
    border: '1px solid #CCCCCC',
    borderRadius: '1px',
    backgroundColor: '#F5F5F5',
    width: '425px',
    height: '200px',
    padding: '0',
  },
  xButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  xButton: {
    padding: '5px 5px 0px 0px',
    cursor: 'pointer',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerText: {
    color: '#212529',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'aktiv-grotesk',
  },
  promptText: { flex: 1, fontSize: '14px' },
  buttonContainer: { display: 'flex', justifyContent: 'space-evenly', paddingTop: '40px', width: '100%' },
  cancelButton: {
    color: '#212529',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    border: '1px solid black',
    marginHorizontal: '30px',
    width: '100px',
    height: '35px',
  },
  deleteButton: {
    backgroundColor: '#212529',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    border: '1px solid black',
    marginHorizontal: '30px',
    width: '100px',
    height: '35px',
  },
};

const duplicateStyle = {
  button: {
    padding: '5%',
    cursor: 'pointer',
    border: 'none',
    fontSize: 14,
    lineHeight: 1,
    backgroundColor: 'unset',
    textTransform: 'uppercase',
  }
};

export default withRouter(withStyles(Title));
